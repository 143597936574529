var ko = require("knockout");
var Browser = require("./BrowserControl");
var ComponentEvent = require("./ComponentEvent");

var w = window;

function FedCredButtonsControl(params)
{
    
    var _this = this;
    

    
    var _fedCredOptions = params.fedCredOptions;
    

    
    _this.onFedCredButtonClick = ComponentEvent.create();
    

    
    _this.fedCredOptions = _fedCredOptions || [];
    

    
    _this.fedCredButton_onClick = function (fedCredButton)
    {
        _this.onFedCredButtonClick(fedCredButton);
    };
    
}

ko.components.register("fed-cred-buttons-control",
    {
        viewModel: FedCredButtonsControl,
        template: require("html/Shared/Controls/ConvergedFedCredButtonsControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = FedCredButtonsControl;